import { Controller } from "@hotwired/stimulus"
import { marked } from "marked"

export default class extends Controller {
  static targets = ["fileList", "fileTemplate"]

  async filesChange(dropzoneEvent) {
    for (const file of dropzoneEvent.detail.files) {
      const listItem = await this._prepareItem(file)
      this.fileListTarget.appendChild(listItem)
    }
  }

  async _prepareItem(file) {
    const item = this.fileTemplateTarget.content.cloneNode(true)

    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(file)

    const fileInput = item.querySelector(".file-input")
    fileInput.files = dataTransfer.files

    const fileNameElement = item.querySelector(".file-name")
    if (fileNameElement) fileNameElement.innerHTML = file.name

    const titleInput = item.querySelector(".title-input")
    if (titleInput) titleInput.value = await this._createTitle(file)

    return item
  }

  async _createTitle(file) {
    let title = null

    switch (file.type) {
      case "text/markdown":
        const markdownContent = await file.text()
        title = this._createTitleFromMarkdown(markdownContent)
        break
      case "text/html":
        const htmlContent = await file.text()
        title = this._createTitleFromHtml(htmlContent)
        break
    }

    if (title) return title

    return this._createTitleFromFileName(file.name)
  }

  _createTitleFromMarkdown(content) {
    const htmlContent = marked.parse(content)
    return this._createTitleFromHtml(htmlContent)
  }

  _createTitleFromHtml(content) {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(content, "text/html")

    if (htmlDoc.title) { return htmlDoc.title }

    const headingElements = htmlDoc.querySelectorAll("h1")
    if (headingElements.length === 1) { return headingElements[0].textContent }

    return null
  }

  _createTitleFromFileName(fileName) {
    if (fileName === null || fileName === '') return fileName

    let title = fileName.toString()

    title = title.replace(/\.[^.]+$/, "") // Remove file extension.
    title = title.replace(/[-_]+/g, " ") // Replace all dashes and underscores with a space.
    title = title.charAt(0).toUpperCase() + title.slice(1) // Uppercase first letter.

    return title
  }
}
