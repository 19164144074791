import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "section"]
  static classes = ["hidden"]

  connect() {
    this.update()
  }

  update() {
    const selectedValue = this.inputTargets.find(i => i.checked).value

    this.sectionTargets.forEach((element) => {
      const isActive = element.dataset.selectRevealerValue === selectedValue
      element.classList.toggle(this.hiddenClass, !isActive)
    })
  }
}
