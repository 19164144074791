import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "label"]

  connect() {
    this.update()
  }

  update() {
    const selectedInput = this.inputTargets.find(i => i.checked)
    this.labelTarget.innerHTML = selectedInput ? selectedInput.dataset.selectValue : ''
  }
}
